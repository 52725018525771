import React, { useEffect, useState } from "react";
import { Config } from "../../constants/Index";
import InputField from "../InputField/InputField";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { BtnLoader } from "../loaders/Loaders";
import DZText from "../text/DZText";
import { useTranslation } from "react-i18next";

const ForgetPasswordPopup = ({
  setLoginPopup,
  setForgetPasswordPopup = true,
  setConfirmationCodePopup = false,
  setUserEmail = false,
}) => {
  const { fnShowSnackBar } = useSnackBarManager();

  const [data, setData] = useState({ email: "" });
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClosePopups();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function handleOpenPopups() {
    //set next model to be opened!
    setUserEmail(data?.email);
    setForgetPasswordPopup(false);
    setConfirmationCodePopup(true);
  }
  function handleClosePopups() {
    setForgetPasswordPopup(false);
    setLoginPopup(true);
  }

  const isValidEmail = (email) => {
    return email.includes("@");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleConfirmationCode = async (e) => {
    e.preventDefault();
    if (data.email) {
      setLoader(true);
      const response = await fetch(`${Config.serverUrl}api/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: data?.email }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message == "Code Send Successfully") {
          handleOpenPopups();
        } else {
          fnShowSnackBar(responseData.message || "Something went wrong");
        }
      } else {
        fnShowSnackBar("Something went wrong");
      }
    } else {
      if (!data.email) {
        fnShowSnackBar("Fill The Required Field!");
      } else if (!isValidEmail(data.email)) {
        fnShowSnackBar("Email is incorrect!");
      }
    }
    setLoader(false);
  };
  return (
    <div className="d-flex flex-col align-center gap-24 position-r p-12 sm-max-w-300">
      <i
        onClick={() => handleClosePopups()}
        className="fa-solid fs-20 dark-color fa-xmark position-abs r-20 transition-03 hover-green cursor-p t-20"
      ></i>

      <h2 className="fs-32 fw-500 dark-color">Enter Your Email</h2>

      <div className="d-flex align-start flex-col gap-12 w-420 sm-max-w-300 w-full">
        <span className="fs-14 dark-color">
          <DZText children={'Common.email_address'} />
          <span className="fs-14 green-color fw-500">*</span>
        </span>


        <InputField
          placeholder={t('Common.email_address')}
          type="email"
          onChange={handleInputChange}
          name="email"
          value={data.email}
        />
      </div>

      <span
        onClick={handleConfirmationCode}
        className="d-flex p-8-12 green-back white-color cursor-p align-center justify-center w-full"
      >
        {loader ? <BtnLoader /> : "Send Code"}
      </span>
    </div>

  );
};

export default ForgetPasswordPopup;
