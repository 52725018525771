
import React, { useEffect, useRef, useState } from 'react'
import { Config, PRODUCT_SIZES } from '../../constants/Index';
import { useAddToCartManager } from '../../lib/customHooks/useAddToCartManager';
import Popup from '../popup/Popup';
import ProductPopup from '../productPopup/ProductPopup';
import DZText from '../text/DZText';
import { useGetAllCategoriesQuery } from '../../redux/storeApis';
import { useDispatch } from 'react-redux';
import { decrementQuantity, removeFromCart } from '../../redux/CartReducer';
import useCartManager from '../../lib/customHooks/useCartManager';

const ViewProductCard = ({ product, productSize }) => {

    const dispatch = useDispatch();
    const productCardCounter = useRef(null);
    const { data: allCategories } = useGetAllCategoriesQuery();
    const { isShowPopUp, setIsShowPopUp, fnSetProductDetail, fnAddToCart } = useAddToCartManager();
    const { fnGetProductQuantity } = useCartManager();
    const [showCount, setShowCount] = useState(false);

    const prodName = product?.title;
    // const prodPriceShow = Config.showCurrency(product?.price);
    const prodPrices = product?.prices;
    const defaultPriceObj = productSize == PRODUCT_SIZES.ALL ? prodPrices?.find((price) => price?.size?.default == 1)
        : prodPrices?.find((price) => price?.size?.title?.en == productSize);
    const prodPriceShow = Config.showCurrency(defaultPriceObj?.price ?? 0);
    const prod_price = defaultPriceObj?.price;

    const imgUrl = Config.getProductImage(product?.image);
    const categoryName = allCategories?.find((category) => category?.id == product?.category_id)?.name;

    const { id, title, image, size_group_id } = product;
    const productData = { id, title, image, size: defaultPriceObj?.size_id, size_group_id, price: prod_price, category_name: categoryName };

    const count = fnGetProductQuantity(product?.id, productData?.size);

    useEffect(() => {
        document.addEventListener("mousedown", fnClickOutside);
        return () => {
            document.removeEventListener("mousedown", fnClickOutside);
        };
    }, []);

    const fnProductAddToCart = () => { fnAddToCart(productData, 1); };

    const fnDecrementProductQuantity = () => {
        if (count == 1) { dispatch(removeFromCart(productData)) }
        else if (count > 0) { dispatch(decrementQuantity(productData)); }
    };

    const fnClickOutside = (event) => {
        if (productCardCounter.current && !productCardCounter.current.contains(event.target)) {
            setShowCount(false);
        }
    };

    const ViewCounter = () => {
        return (
            <div
                ref={productCardCounter}
                onClick={() => setShowCount(true)}
                className={`${showCount ? 'w-110' : 'w-40'} cursor-p overflow-h d-flex align-center p-8 gap-8 position-abs z-0 shadow-lg dark-color r-0 t-0 light-back transition-03`}
                style={{ borderRadius: '0 0 0 10px', justifyContent: count ? 'center' : 'end', background: !showCount && count ? 'var(--green-color)' : 'var(--white-color)' }}
            >

                <i onClick={() => fnDecrementProductQuantity()} className="fa-solid fa-minus fs-14 cursor-p transition-03 p-6 radi-6 hover-green white-back"></i>

                <span className="counter__number fs-14 transition-03" style={{ padding: '0 10px', color: !showCount && count ? 'var(--white-color)' : 'var(--dark-color)' }} >
                    {count}
                </span>

                <i onClick={() => fnProductAddToCart()} className="fa-solid fa-plus fs-14 cursor-p transition-03 p-6 radi-6 hover-green white-back"></i>

            </div>
        )
    };

    return (
        <>

            <div className={` ${showCount && 'z-2'} d-flex flex-col align-start gap-12 radi-12 shadow-sm h-full overflow-h pb-3 w-full position-rel`}>

                <div className="d-flex align-center w-full h-160 flex-col">

                    <img onClick={() => fnSetProductDetail(productData)} src={imgUrl} className=' h-160 cursor-p' alt="" />

                </div>

                <div className="d-flex flex-col gap-4 pl-2">

                    <span className="fw-500 fs-14 green-color price__span">
                        {prodPriceShow}
                    </span>

                    <span className="fw-500 fs-14 dark-color product__name">
                        <DZText>{prodName}</DZText>
                    </span>

                </div>

                <ViewCounter />
            </div>
            {isShowPopUp && <Popup
                onClose={() => setIsShowPopUp(false)}
                children={<ProductPopup setIsShowPopUp={setIsShowPopUp} />}
            />
            }
        </>
    )
}

export default ViewProductCard