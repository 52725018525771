import React, { createContext, useContext, useState } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popups, setPopups] = useState({
    permissionPopup: { status: false, title: '' },
    loginPopup: false,
    registerPopup: false,
    forgetPasswordPopup: false,
    confirmationCodePopup: false,
    userEmail: '',
  });

  return (
    <PopupContext.Provider value={{ popups, setPopups }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopupContext = () => useContext(PopupContext);
