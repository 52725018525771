import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Config } from '../constants/Index';

const TAG_TYPES = { user: 'user', address: 'address' }
export const onlineStoreApi = createApi({
    reducerPath: 'onlineStoreApi',
    baseQuery: fetchBaseQuery({
        baseUrl: Config.serverApiUrl,
        prepareHeaders: async (headers, { getState, endpoint }) => {

            const storedToken = localStorage.getItem(Config.userToken);
            if (storedToken && endpoint !== 'refresh') headers.set('Authorization', `Bearer ${storedToken}`);

            return headers;
        },
    }),
    tagTypes: [TAG_TYPES.user, TAG_TYPES.address],
    endpoints: (builder) => ({
        getProducts: builder.query({ query: () => `products`, transformResponse: (response) => response?.data }),
        getAllCategories: builder.query({ query: () => `all_categories`, transformResponse: (response) => response?.data }),
        getCategories: builder.query({ query: () => `categories`, transformResponse: (response) => response?.data }),
        getCategoryById: builder.query({ query: (id) => `categories/${id}`, transformResponse: (response) => response?.data }),
        getAllDeals: builder.query({ query: () => "deals-products", transformResponse: (response) => response?.data }),
        getProductById: builder.query({ query: (id) => `products/${id}`, }),
        getUserFavorites: builder.query({ query: () => "user-favourites", }),
        getRestaurantDetails: builder.query({ query: () => "restaurants/1", }),
        getAllOrdersList: builder.query({ query: () => "user-order-detail", transformResponse: (response) => response?.data }),
        getSizes: builder.query({ query: () => "sizes", transformResponse: (response) => response?.data?.sizes }),
        getAllHeros: builder.query({ query: () => 'get-all-heros', transformResponse: (response) => response?.data }),
        
        getDeliveryHours: builder.query({ query: () => "get-delivery-hours", transformResponse: (response) => response?.data?.delivery_hour }),
        getDeliveryPostCode: builder.query({ query: () => "get-delivery-post-code" }),
        
        userAddresses: builder.query({ query: () => "user-address", providesTags: () => [TAG_TYPES.address] }),
        userAddAddress: builder.mutation({ query: (data) => ({ url: `user-address`, method: "POST", body: data, }), invalidatesTags: [TAG_TYPES.address] }),
        deleteAddress: builder.mutation({ query: (id) => ({ url:`user-address/${id}`, method:"DELETE" }), invalidatesTags: [TAG_TYPES.address] }),
        addOrder: builder.mutation({ query: (data) => ({ url: `add-orders`, method: "POST", body: data, }) }),
        
        changePassword: builder.mutation({ query: (data) => ({ url: `user/change-password`, method: "POST", body: data, }) }),

        addUser: builder.mutation({ query: (data) => ({ url: `signup`, method: "POST", body: data, }), }),
        getUserData: builder.query({ query: () => "me", providesTags: () => [TAG_TYPES.user] }),
        loginUser: builder.mutation({ query:(data) => ({url:'login', method:'POST', body: data}), invalidatesTags:[TAG_TYPES.user]  }),
        userUpdateData: builder.mutation({ query: (data) => ({ url: `userupdate`, method: "POST", body: data, }), invalidatesTags: [TAG_TYPES.user] }),

        addQuery: builder.mutation({ query: (data) => ({ url: `query-send`, method: "POST", body: data, }) }),

        getGalleries: builder.query({ query: () => "gallery-images" }),

    }),
});

export const { useGetProductsQuery, useGetCategoriesQuery, useGetAllDealsQuery, useGetProductByIdQuery, useGetDeliveryHoursQuery, useGetSizesQuery, useGetAllHerosQuery,
    useGetUserFavoritesQuery, useGetAllOrdersListQuery, useAddUserMutation, useGetUserDataQuery, useUserUpdateDataMutation, useChangePasswordMutation, useUserAddressesQuery,
    useAddOrderMutation, useGetAllCategoriesQuery, useGetRestaurantDetailsQuery, useUserAddAddressMutation, useAddQueryMutation,
    useGetCategoryByIdQuery, useDeleteAddressMutation, useLoginUserMutation, useGetGalleriesQuery } = onlineStoreApi;

