import React from 'react'
import DZText from '../../../../components/text/DZText'

const HeroSection = () => {
    return (
        <div className='flex items-center justify-between max-sm:jusitfy-center max-lg:flex-col gap-[32px] w-full max-inner py-[64px]'>
            <div className="flex max-sm:items-center  max-lg:text-center flex-col max-sm:gap-[24px] gap-[48px] w-full">
                <div className="flex flex-col gap-[12px]">
                    <DZText className='text-[#101828] text-[60px] font-semibold max-lg:text-[48px] max-md:text-[36px]'>
                        {"Hero.title"}
                    </DZText>
                    <DZText className='text-[#475467] text-[20px] max-lg:text-center max-lg:text-[18px] max-md:text-[16px]'>
                        {"Hero.detail"}
                    </DZText>
                </div>
                <div className="flex flex-col gap-[16px] max-lg:items-center">
                    <div className="flex items-center gap-[16px]">
                        <a target='_blank' href='https://www.pakkeshop.dk/'>
                        {/* <a target='_blank' href='https://gls-group.com/GROUP/en/parcel-tracking'> */}
                            <img src="./images/1.png" alt="" className='max-w-[48px] max-sm:max-w-[32px] cursor-pointer' />
                        </a>

                        <a target='_blank' href='https://www.postnord.dk/en/tools/track-and-trace'>
                            <img src="./images/5.png" alt="" className='max-w-[48px] max-sm:max-w-[32px] cursor-pointer rounded-full' />
                        </a>

                        <a target='_blank' href='https://dao.as/find-din-pakke/'>
                            <div className="w-[48px] max-sm:max-w-[32px] cursor-pointer rounded-full flex items-center justify-center p-[6px] h-[48px] bg-[red]">
                                <img src="./images/4.png" alt="" className='w-full' />
                            </div>
                        </a>

                        <a target='_blank' href='https://www.ups.com/track?loc=en_DK&requester=ST/'>
                            <img src="./images/3.png" alt="" className='max-w-[48px] max-sm:max-w-[32px] cursor-pointer' />
                        </a>

                        <a target='_blank' href='https://www.bring.dk/en/track-a-shipment' >
                            <img src="./images/2.png" alt="" className='max-w-[48px] max-sm:max-w-[32px] cursor-pointer' />
                        </a>


                    </div>
                </div>
            </div>
            <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/pe7IdnFwpb8?si=QHBExKJI9p_qK0Gt&autoplay=1&mute=1&loop=1&playlist=pe7IdnFwpb8"
                title="YouTube video player"
                className="w-full rounded-[12px] max-sm:max-h-[400px]"
                frameborder="0"
                allowFullScreen={true}
                allow="autoplay"
                autoplay
            ></iframe>
        </div>
    )
}

export default HeroSection