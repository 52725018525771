import React, { useEffect, useState } from 'react'
import './Cart.css'
import { useDispatch, useSelector } from 'react-redux'
import { decrementQuantity, emptyCart, incrementQuantity, removeFromCart, selectedCartSelector } from '../../redux/CartReducer'
import { Config, ORDER_TYPE } from '../../constants/Index'
import { useAddOrderMutation, useGetDeliveryHoursQuery, useGetUserDataQuery, useUserAddressesQuery } from '../../redux/storeApis'
import DawaAutoAddress from '../../components/dawa-address/DawaAutoAddress'
import { useNavigate } from 'react-router-dom'
import useCartManager from '../../lib/customHooks/useCartManager'
import { ROUTES } from '../../reactRoute/RouteConstants'
import { selectedLoginUser } from '../../redux/UserReducer'
import ViewUserAddress from '../../components/views/ViewUserAddress'
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager.js'
import NoProduct from '../home/components/noProduct/NoProduct.jsx'
import { BtnLoader } from '../../components/loaders/Loaders.jsx'
import ViewPopUps from '../../components/views/ViewPopUps.jsx'
import Popup from '../../components/popup/Popup.jsx'
import DZText from '../../components/text/DZText.jsx'

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectedLoginUser);
    const { fnShowCartTotalPrice, fnGetTotalPrice, calculateVat, fnShowCartTotalPriceWithoutVat } = useCartManager();
    const { fnShowSnackBar } = useSnackBarManager();

    const { data: userData, refetch: refetchUser } = useGetUserDataQuery();
    const { data: userAddressList, refetch: refetchAddressList, isSuccess: isSuccessUserAddressList } = useUserAddressesQuery();
    const { data: deliveryHours } = useGetDeliveryHoursQuery();
    const [addOrder] = useAddOrderMutation();
    const [userInfo, setUserInfo] = useState();

    const cartTypes = { shopping_cart: 'shopping_cart', checkout: 'checkout', order_complete: 'order_complete' };
    const cartItemsList = useSelector(selectedCartSelector);
    const [selectedType, setSelectedType] = useState(cartTypes.shopping_cart);
    const [addressPopup, setAddressPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [comments, setComments] = useState('');
    const [addressId, setAddressId] = useState(null);
    const [selectedOrderType, setSelectedOrderType] = useState(ORDER_TYPE.PICK_UP);
    const [handlePopUps, setHandlePopUps] = useState(0);

    const { mon_from, mon_to, tue_from, tue_to, wed_from, wed_to, thu_from, thu_to, fri_from, fri_to, sat_from, sat_to, sun_from, sun_to } = deliveryHours ?? {};
    const daysClassName = 'w-full text-center green-back p-4-6 white-color radi-4';

    useEffect(() => { setUserInfo(userData?.data?.user); }, [userData]);
    useEffect(() => {
        const byDefaultAddressId = userAddressList?.data[0]?.id;
        fnSetAddressId(byDefaultAddressId);
    }, [isSuccessUserAddressList]);

    const fnCartTypeClassName = (type) => {
        const mainClass = 'tab d-flex align-center justify-center gap-12 position-rel w-full p-10 cursor-p';
        return selectedType == type ? `${mainClass} active__tab` : mainClass;
    };

    const fnProductRemoveFromCart = (product) => {
        dispatch(removeFromCart(product));
        fnShowSnackBar('Product removed from cart')
    };

    const fnCheckLoginStatus = () => {
        if (cartItemsList?.length) {
            if (isLoggedIn) { setSelectedType(cartTypes.checkout); refetchUser(); }
            else { setHandlePopUps((pre) => pre + 1); }
        }
    };

    const fnAddNewOrder = async () => {

        const checkOrderType = selectedOrderType == ORDER_TYPE.PICK_UP ? true : userInfo?.street;

        if (isLoggedIn && checkOrderType) {
            try {
                setLoader(true);
                const totalPrice = fnGetTotalPrice();
                const form = new FormData();
                form.append("amount", totalPrice);
                form.append("payment_type", "cod");
                form.append("comments", comments);
                form.append("cartItems", JSON.stringify(cartItemsList));
                form.append("delivery_type", selectedOrderType);
                if (selectedOrderType == ORDER_TYPE.DELIVER) {
                    form.append("user_address_id", userInfo?.user_address_id);
                };
                if (isLoggedIn) {
                    addOrder(form)
                        .unwrap()
                        .then((response) => {
                            if (response?.success) {
                                fnShowSnackBar('Order Placed Successfully');
                                dispatch(emptyCart());
                                setSelectedType(cartTypes.order_complete);
                                setLoader(false);
                            } else {
                                fnShowSnackBar('Some Error Occurred');
                                setLoader(false);
                            }
                        })
                        .catch((error) => {
                            fnShowSnackBar('Some Error Occurred');
                            setLoader(false);
                        });
                } else {
                    navigate("/");
                }
            } catch (error) {
                fnShowSnackBar('Some Error Occurred');
                setLoader(false);
            }
        } else if (!userInfo.street) {
            fnShowSnackBar('Address field must be filled');
            setLoader(false);
        }
    };

    const fnSetAddressId = (id) => { setAddressId(id) };

    function fnGetTime_hh_mm(timeString) {
        const [hours, minutes] = timeString.split(':').slice(0, 2);
        return `${hours}:${minutes}`;
    };


    const ViewShoppingCart = () => {
        return (
            <div className="d-flex align-start gap-20 sm-flex-col">

                <div className="w-full d-flex align-center flex-col mt-4 border-grey sm-overflow-scroll">

                    <div className="d-grid grid-colt-6 w-full border-b-1-grey mb-2 p-12">

                        <span className="fs-18 fw-600 dark-color text-center sm-min-w-150">
                            <DZText children={'Cart.image'}/>
                        </span>

                        <span className="fs-18 fw-600 dark-color text-center sm-min-w-150">
                            <DZText children={'Cart.product_name'}/>
                        </span>

                        <span className="fs-18 fw-600 dark-color text-center sm-min-w-150">
                            <DZText children={'Cart.price'}/>
                        </span>

                        <span className="fs-18 fw-600 dark-color text-center sm-min-w-150">
                            <DZText children={'Cart.quantity'}/>
                        </span>

                        <span className="fs-18 fw-600 dark-color text-center sm-min-w-150">
                            <DZText children={'Common.total'}/>
                        </span>

                        <div className="d-flex align-center justify-center sm-min-w-150">
                            <div onClick={() => dispatch(emptyCart())} className="d-flex p-4 align-center hover-green transition-03 cursor-p justify-center border-grey w-fit">
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        </div>

                    </div>

                    {cartItemsList?.map((cartProd, i) => {

                        const prod_id = cartProd?.id;
                        const prod_name = cartProd?.title?.en;
                        const prod_price = cartProd?.price;
                        const prod_quantity = cartProd?.quantity;
                        const show_prod_price = Config.showCurrency(cartProd?.price);
                        const show_prod_img = Config.getProductImage(cartProd?.image);
                        const show_prod_total_price = Config.showCurrency((prod_quantity * prod_price).toFixed(2));

                        return (
                            <div key={i} className="d-grid grid-colt-6 w-full mb-2 p-12 ">

                                <div className="d-flex align-center justify-center sm-min-w-150">
                                    <img src={show_prod_img} className='w-140' alt="" />
                                </div>

                                <div className="d-flex align-center justify-center sm-min-w-150">
                                    <span className="fs-18 fw-600 dark-color text-center">
                                        {prod_name}
                                    </span>
                                </div>

                                <div className="d-flex align-center justify-center sm-min-w-150">
                                    <span className="fs-18 fw-600 dark-color text-center">
                                        {show_prod_price}
                                    </span>
                                </div>

                                <div className="d-flex align-center justify-center sm-min-w-150">

                                    <div className="d-flex align-center justify-center radi-4 border-grey">

                                        <div className="d-flex align-center justify-center h-54 p-0-20 w-30 border-r-1-grey ">
                                            <span className="fs-14 fw-600 dark-color text-center">
                                                {prod_quantity}
                                            </span>
                                        </div>

                                        <div className="d-flex flex-col">

                                            <span onClick={() => dispatch(incrementQuantity(cartProd))} className="fs-18 fw-600 dark-color text-center cursor-p hover-green transition-03 border-b-1-grey p-0-8">
                                                +
                                            </span>

                                            <span onClick={() => dispatch(decrementQuantity(cartProd))} className="fs-18 fw-600 dark-color text-center cursor-p hover-green transition-03 p-0-8">
                                                -
                                            </span>

                                        </div>

                                    </div>

                                </div>

                                <div className="d-flex align-center justify-center sm-min-w-150">
                                    <span className="fs-18 fw-600 dark-color text-center">
                                        {show_prod_total_price}
                                    </span>
                                </div>

                                <div className="d-flex align-center justify-center sm-min-w-150">
                                    <div onClick={() => { fnProductRemoveFromCart(cartProd); }} className="d-flex p-4 align-center hover-green transition-03 cursor-p justify-center border-grey w-fit h-fit">
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>

                            </div>
                        )
                    })}

                </div>

                <div className="mt-4 d-flex flex-col align-start gap-16 w-340 p-12-0 sm-min-w-full">

                    <span className="fs-18 fw-600 dark-color">
                        <DZText children={'Cart.cart_total'} />
                    </span>

                    <div className="d-flex border-grey w-full flex-col gap-12">

                        <div className="d-flex  space-between border-b-1-grey align-center p-16 gap-24">

                            <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                <DZText children={'Common.sub_total'}/>
                            </span>

                            <span className="fs-14 fw-500 dark-color">
                                {fnShowCartTotalPriceWithoutVat()}
                            </span>

                        </div>

                        {/* <div className="d-flex border-b-1-grey align-center p-16 gap-24">

                            <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                Shipping
                            </span>

                            <span className="fs-14 fw-500 dark-color">
                                Free shipping
                            </span>

                        </div> */}

                        <div className="d-flex space-between align-center border-b-1-grey p-16 gap-24">

                            <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                <DZText children={'Common.vat'}/>
                                <DZText className={'text-sm fs-10 ml-1'} children={'Common.included'}/>
                            </span>

                            <span className="fs-14 fw-500 dark-color">
                                {calculateVat()}
                            </span>

                        </div>
                        
                        <div className="d-flex space-between align-center p-16 gap-24">

                            <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                <DZText children={'Common.total'}/>
                            </span>

                            <span className="fs-14 fw-500 dark-color">
                                {fnShowCartTotalPrice()}
                            </span>

                        </div>

                    </div>

                    <span onClick={() => { fnCheckLoginStatus(); }} className="w-full p-6-12 green-back radi-40 text-center cursor-p white-color fw-500">
                        <DZText children={'Common.checkout'} />
                    </span>

                </div>

            </div>
        )
    };

    const ViewContinueShopping = () => {
        return (
            <div className='d-flex align-center space-between gap-24 w-full h-5vh'>

                <div className="d-flex flex-col align-start gap-12">

                    <h1 className='text-center green-color fs-48 fw-500 md-fs-32'>{'Order Placed'}</h1>

                    <p className="dark-color fs-16" style={{ lineHeight: '22px' }}>
                        <DZText children={'Cart.order_complete_detail'}/>
                    </p>

                    <span onClick={() => navigate(ROUTES.shop)} className="green-back p-8-16 text-center white-color cursor-p mt-1">
                        <DZText children={'Cart.continue_shopping'} />
                    </span>

                </div>

                {selectedOrderType == ORDER_TYPE.DELIVER &&
                    <div className='d-flex align-center flex-col gap-16 min-w-320'>
                        <DZText className={'text-center green-color fs-24 fw-400 md-fs-32'} children={'Delivery Hours'} />
                        <div className='d-flex align-center flex-col gap-4 min-w-300'>
                            {mon_from && mon_to && <DZText className={daysClassName} children={`Monday ${fnGetTime_hh_mm(mon_from)} - ${fnGetTime_hh_mm(mon_to)}`} />}
                            {tue_from && tue_to && <DZText className={daysClassName} children={`Tuesday ${fnGetTime_hh_mm(tue_from)} - ${fnGetTime_hh_mm(tue_to)}`} />}
                            {wed_from && wed_to && <DZText className={daysClassName} children={`Wednesday ${fnGetTime_hh_mm(wed_from)} - ${fnGetTime_hh_mm(wed_to)}`} />}
                            {thu_from && thu_to && <DZText className={daysClassName} children={`Thursday ${fnGetTime_hh_mm(thu_from)} - ${fnGetTime_hh_mm(thu_to)}`} />}
                            {fri_from && fri_to && <DZText className={daysClassName} children={`Friday ${fnGetTime_hh_mm(fri_from)} - ${fnGetTime_hh_mm(fri_to)}`} />}
                            {sat_from && sat_to && <DZText className={daysClassName} children={`Saturday ${fnGetTime_hh_mm(sat_from)} - ${fnGetTime_hh_mm(sat_to)}`} />}
                            {sun_from && sun_to && <DZText className={daysClassName} children={`Sunday ${fnGetTime_hh_mm(sun_from)} - ${fnGetTime_hh_mm(sun_to)}`} />}
                        </div>
                    </div>
                }
            </div>
        )
    };

    return (
        <>
            <div className="d-flex align-center justify-center">

                <div className="max-inner w-full">

                    {/* Cart Header */}

                    <div className="d-flex align-center justify-center w-full mt-4 gap-24 sm-flex-col">

                        <div onClick={() => setSelectedType(cartTypes.shopping_cart)} className={fnCartTypeClassName(cartTypes.shopping_cart)}>

                            <span className="fw-600 tab__number">
                                01
                            </span>

                            <span className="tab__name dark-color fs-20 fw-600 text">
                                <DZText children={'Cart.shopping_cart'} />
                            </span>

                        </div>

                        <div onClick={() => { fnCheckLoginStatus() }} className={fnCartTypeClassName(cartTypes.checkout)}>

                            <span className="fw-600 tab__number">
                                02
                            </span>

                            <span className="tab__name dark-color fs-20 fw-600 text">
                                <DZText children={'Common.checkout'} />
                            </span>

                        </div>

                        <div className={selectedType == cartTypes.order_complete ? 'tab d-flex align-center justify-center gap-12 position-rel w-full p-10 active__tab' : 'tab d-flex align-center justify-center gap-12 position-rel w-full p-10 '}>

                            <span className="fw-600 tab__number">
                                03
                            </span>

                            <span className="tab__name dark-color fs-20 fw-600 text">
                                <DZText children={'Cart.order_complete'} />
                            </span>

                        </div>

                    </div>

                    {
                        selectedType == cartTypes.shopping_cart ? (cartItemsList?.length ? <ViewShoppingCart /> : <NoProduct />)
                            : selectedType == cartTypes.checkout ? <>
                                <div className="d-flex align-start gap-20 sm-flex-col">

                                    {/* Checkout Form */}

                                    <div className="w-full d-flex flex-col align-start gap-24 mt-4">

                                        <span className="fs-20 fw-600 dark-color">
                                            <DZText children={'Cart.billing_details'} />
                                        </span>

                                        <div className="w-full d-grid grid-colt-2 gap-24 gap-r-12">

                                            <div className="d-flex flex-col align-start gap-12">

                                                <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                    <span className='green-color fs-18'>*</span>
                                                    <span className='fw-400'><DZText children={'Common.full_name'} /></span>
                                                </span>

                                                <input type="text" value={userInfo?.name} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

                                            </div>

                                            <div className="d-flex flex-col align-start gap-12">

                                                <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                    <span className='green-color fs-18'>*</span>
                                                    <span className='fw-400'><DZText children={'Common.phone'} /></span>
                                                </span>

                                                <input type="text" value={userInfo?.mobile} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

                                            </div>

                                            {selectedOrderType == ORDER_TYPE.DELIVER ?
                                                <>
                                                    <div className="d-flex flex-col align-start gap-12 grid-col-1-3">

                                                        {/* <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                            <span className='green-color fs-18'>*</span>
                                                            <span className='fw-400'>Add New Address</span>
                                                        </span> */}

                                                        {/* <DawaAutoAddress refetchAddressList={refetchAddressList} setUserInfo={setUserInfo} placeholder={'Search your address'} /> */}

                                                        <span className='fs-14 fw-600 dark-color d-flex align-center space-between w-full gap-4'>

                                                            <span className='fw-400'>
                                                                <span className='green-color fs-18'>*</span>
                                                                <DZText children={'Common.address'} />
                                                            </span>

                                                            <button className="h-26 w-26 cursor-p border-none white-color green-back d-flex align-center justify-center" onClick={() => setAddressPopup(true)}>
                                                                <i className="ri-add-line fs-20"></i>
                                                            </button>

                                                        </span>

                                                        <ViewUserAddress
                                                            userAddressList={userAddressList}
                                                            setAddressId={setAddressId}
                                                            addressId={addressId}
                                                            fnSetAddressId={fnSetAddressId}
                                                            setUserInfo={setUserInfo}
                                                        />

                                                        {/* <input type="text" value={userInfo?.street} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' /> */}


                                                    </div>

                                                    {/* <div className="d-flex flex-col align-start gap-12 grid-col-1-3">

                                                        <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                            <span className='fw-400'>Postcode / ZIP (optional)</span>
                                                        </span>

                                                        <input type="text" value={userInfo?.postCode} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

                                                    </div>

                                                    <div className="d-flex flex-col align-start gap-12">

                                                        <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                            <span className='green-color fs-18'>*</span>
                                                            <span className='fw-400'>Town / City</span>
                                                        </span>

                                                        <input type="text" value={userInfo?.city} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

                                                    </div> */}
                                                </>
                                                : <></>
                                            }

                                            <div className="d-flex flex-col align-start gap-12 grid-col-1-3">

                                                <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                    <span className='green-color fs-18'>*</span>
                                                    <span className='fw-400'><DZText children={'Common.email_address'} /></span>
                                                </span>

                                                <input type="text" value={userInfo?.email} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

                                            </div>

                                            <div className="d-flex flex-col align-start gap-12 grid-col-1-3">

                                                <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
                                                    <span className='green-color fs-18'>*</span>
                                                    <span className='fw-400'><DZText children={'Cart.order_notes'} /> (<DZText children={'Common.optional'}/>)</span>
                                                </span>

                                                <input value={comments} onChange={(e) => setComments(e.target.value)} type="text" className='p-8-10 outline-none border-grey dark-color w-full h-200' />

                                            </div>

                                        </div>

                                    </div>

                                    {/* Checkout Right Side */}

                                    <div className="mt-4 d-flex flex-col align-start gap-16 w-440 p-12-0 sm-min-w-full sm-w-auto">

                                        <span className="fs-18 fw-600 dark-color">
                                            <DZText children={'Cart.cart_total'} />
                                        </span>

                                        <div className="d-flex w-full flex-col gap-12 sm-min-w-full">

                                            {cartItemsList?.map((cartProd, i) => {
                                                const prod_name = cartProd?.title?.en;
                                                const prod_price = cartProd?.price;
                                                return (
                                                    <div key={i} className="d-flex border-b-1-grey align-center p-16 gap-24 space-between">
                                                        <span className="fs-14 fw-400 dark-color opacity-08 w-full">
                                                            {prod_name}
                                                        </span>
                                                        <span className="fs-14 fw-500 dark-color">
                                                            {Config.showCurrency(prod_price)}
                                                        </span>
                                                    </div>
                                                )
                                            })}

                                            <div className="d-flex border-b-1-grey align-center p-16 gap-24 space-between">

                                                <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                                    <DZText children={'Common.sub_total'} />
                                                </span>

                                                <span className="fs-14 fw-500 dark-color">
                                                    {fnShowCartTotalPriceWithoutVat()}
                                                </span>

                                            </div>

                                            {/* <div className="d-flex border-b-1-grey align-center p-16 gap-24 space-between">

                                                <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                                    Shipping
                                                </span>

                                                <span className="fs-14 fw-400 dark-color">
                                                    Free shipping
                                                </span>

                                            </div> */}

                                            <div className="d-flex border-b-1-grey align-center p-16 gap-24 space-between">

                                                <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                                    <DZText children={'Common.vat'} />
                                                </span>

                                                <span className="fs-14 fw-500 dark-color">
                                                    {calculateVat()}
                                                </span>

                                            </div>

                                            <div className="d-flex align-center p-16 gap-24 space-between">

                                                <span className="fs-16 fw-600 dark-color opacity-08 w-60">
                                                    <DZText children={'Common.total'} />
                                                </span>

                                                <span className="fs-14 fw-500 dark-color">
                                                    {fnShowCartTotalPrice()}
                                                </span>

                                            </div>

                                        </div>

                                        <div className="d-flex flex-col gap-16">
                                            <div className="d-flex align-center gap-8">
                                                <input
                                                    type="radio"
                                                    name={'delivery_type'}
                                                    id='cashOnDelivery'
                                                    value={ORDER_TYPE.DELIVER}
                                                    onChange={() => { setSelectedOrderType(ORDER_TYPE.DELIVER); refetchAddressList() }}
                                                    checked={selectedOrderType == ORDER_TYPE.DELIVER}
                                                />
                                                <label htmlFor='cashOnDelivery' className="cursor-pointer fs-14 dark-color fw-500">
                                                    <DZText children={'Common.delivery'}/>
                                                </label>
                                            </div>

                                            <div className="d-flex align-center gap-8">
                                                <input
                                                    name={'delivery_type'}
                                                    id='paypal'
                                                    type="radio"
                                                    value={ORDER_TYPE.PICK_UP}
                                                    onChange={() => setSelectedOrderType(ORDER_TYPE.PICK_UP)}
                                                    checked={selectedOrderType == ORDER_TYPE.PICK_UP}
                                                />
                                                <label htmlFor='paypal' className="cursor-pointer fs-14 dark-color fw-500">
                                                    <DZText children={'Common.pick_up'}/>
                                                </label>
                                            </div>
                                        </div>


                                        <span onClick={() => fnAddNewOrder()} className="w-full p-6-12 green-back radi-40 text-center cursor-p white-color fw-500">
                                            {loader ? <BtnLoader loaderStyle={{ height: "15px", paddingLeft: "12px", paddingRight: "12px", }} />
                                                : <DZText children={'Common.place_order'}/>}
                                        </span>

                                    </div>

                                </div>
                            </>
                                : <ViewContinueShopping />
                    }


                </div>

            </div >

            <ViewPopUps handlePopUps={handlePopUps} />

            {/* {addressPopup &&
                <div className="d-flex align-center flex-col position-fixed shadow-4-0-2000-grey t-50p l-50p wax-w-820 gap-24 transform-50p white-back radi-4 z-2 p-24 min-w-800 sm-min-w-360">

                    <DawaAutoAddress
                        refetchAddressList={refetchAddressList}
                        setUserInfo={setUserInfo}
                        placeholder={'Search your address'}
                        setAddressPopup={setAddressPopup}
                    />

                </div>
            } */}
            {addressPopup &&
                <Popup
                    onClose={()=>setAddressPopup(false)}
                    className={'d-flex align-center flex-col position-fixed shadow-4-0-2000-grey t-50p l-50p wax-w-820 gap-24 transform-50p white-back radi-4 z-2 p-24 min-w-800 sm-min-w-360'}
                    children={<DawaAutoAddress refetchAddressList={refetchAddressList} setUserInfo={setUserInfo} placeholder={'Search your address'} setAddressPopup={setAddressPopup} />}
                />
            }
        </>
    )
}

export default Cart;