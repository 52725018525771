import React, { useEffect, useState } from "react";
import ViewProfile from "../../components/views/ViewProfile";
import ViewOrderInfo from "../../components/views/ViewOrderInfo";
import ViewUserAddress from "../../components/views/ViewUserAddress";
import Addresses from "../../components/addressess/Addresses";
import DZText from "../../components/text/DZText";
import { useUserManager } from "../../lib/customHooks/useUserManager";

const ProfilePage = () => {
  const {refetchUser} = useUserManager();

  const states = { profile: 'profile', address: 'address', orderInfo: 'orderInfo' };
  const activeStateClassName = 'cursor-p fs-14 green-back white-color p-6';
  const inActiveStateClassName = 'cursor-p fs-14 dark-color p-6 hover-green transition-03';

  const [activeState, setActiveState] = useState(states.profile);

  useEffect(()=>{ refetchUser(); },[]);

  const fnGetActiveClassName = (state) => {
    return activeState == state ? activeStateClassName : inActiveStateClassName
  };

  return (
    <div className="d-flex justify-center w-full min-h-4vh">

      <div className="d-flex gap-24 max-inner w-full sm-flex-col">

        <div className="d-flex flex-col border-grey h-fit min-w-170">

          <span onClick={() => setActiveState(states.profile)} className={fnGetActiveClassName(states.profile)}>
            <DZText children={'Common.profile'} />
          </span>

          <span onClick={() => setActiveState(states.address)} className={fnGetActiveClassName(states.address)}>
            <DZText children={'Common.addresses'} />
          </span>

          <span onClick={() => setActiveState(states.orderInfo)} className={fnGetActiveClassName(states.orderInfo)}>
            <DZText children={'Common.order_info'} />
          </span>

        </div>

        {activeState == states.profile ? <ViewProfile />
          : activeState == states.address ? <Addresses />
            : <ViewOrderInfo />}

      </div>

    </div >
  );
};

export default ProfilePage;

// import React, { useEffect, useState } from "react";
// import {
//   useGetUserDataQuery,
//   useUserUpdateDataMutation,
//   useChangePasswordMutation, // Import the mutation hook for changing the password
// } from "../../redux/storeApis";
// import { BtnLoader, Loader } from "../../components/loaders/Loaders";
// import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
// import { useUserManager } from "../../lib/customHooks/useUserManager";

// const ProfilePage = () => {
//   const { fnShowSnackBar } = useSnackBarManager();
//   const { userName, userEmail, userMobile, isSuccessUser, isLoadingUser } = useUserManager();
//   // const { data, isSuccess, isLoading: isLoadingUserData, } = useGetUserDataQuery();

//   const [updateUserInfo] = useUserUpdateDataMutation();
//   const [changePassword] = useChangePasswordMutation(); // Initialize the change password mutation hook
//   // const userDetails = data?.data?.user;
//   // const userName = userDetails?.name;
//   // const userEmail = userDetails?.email;
//   // const userMobile = userDetails?.mobile;

//   const [userData, setUserData] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     oldPassword: "",
//     newPassword: "",
//     confirmNewPassword: "",
//   });
//   const [loader, setLoader] = useState(false);

//   const { name, email, mobile, oldPassword, newPassword, confirmNewPassword } = userData;

//   useEffect(() => { setUserData({ ...userData, name: userName, email: userEmail, mobile: userMobile, }); }, [isSuccessUser]);

//   const fnInputChange = (e) => {
//     const { name, value } = e.target;
//     setUserData({ ...userData, [name]: value });
//   };

//   const fnUpdateUserData = () => {
//     if (userName != name || userMobile != mobile) {
//       setLoader(true);
//       updateUserInfo({ name: name, mobile: mobile, })
//         .unwrap()
//         .then((res) => {
//           console.log(res);
//           fnShowSnackBar("User data updated");
//           setLoader(false);
//           // if (oldPassword && newPassword && confirmNewPassword) {
//           //   fnChangePassword();
//           // } else {
//           //   fnShowSnackBar("User data updated");
//           //   setLoader(false);
//           // }
//         })
//         .catch((err) => {
//           setLoader(false);
//           fnShowSnackBar('Some error occurred');
//         });
//     }
//   };

//   const fnChangePassword = () => {
//     if (newPassword == confirmNewPassword) {
//       changePassword({
//         oldPassword: oldPassword,
//         newPassword: newPassword,
//         confirmNewPassword: confirmNewPassword,
//       })
//         .unwrap()
//         .then((res) => {
//           console.log(res);
//           setLoader(false);
//           fnShowSnackBar('User Data Updated')
//         })
//         .catch((err) => console.log(err));
//     } else {
//       fnShowSnackBar('Password not match!');
//       setLoader(false);
//     }
//   };

//   return (
//     <div className="d-flex justify-center w-full ">

//       <div className="d-flex flex-col border-grey h-fit min-w-200">

//         <span className="cursor-p fs-16 green-back white-color p-12">
//           Profile
//         </span>

//         <span className="cursor-p fs-16 dark-color p-12 hover-green transition-03">
//           Addresses
//         </span>

//         <span className="cursor-p fs-16 dark-color p-12 hover-green transition-03">
//           Order Info
//         </span>

//       </div>

//       <div className="d-flex gap-24 w-full max-inner">

//         <div className="d-flex align-center flex-col gap-24 w-full">

//           <span className="fs-24 fw-600 dark-color">Profile</span>

//           {isLoadingUser ? (
//             <Loader svgClassName={"w-60 h-60"} loaderContainerStyle={{ height: '300px' }} />
//           ) : (
//             <div className="w-full d-grid grid-colt-2 gap-24 gap-r-12 sm-d-flex flex-col">
//               <div className="d-flex flex-col align-start gap-12">
//                 <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
//                   <span className="green-color fs-18">*</span>
//                   <span className="fw-400">Name</span>
//                 </span>
//                 <input
//                   type="text"
//                   value={name}
//                   name="name"
//                   onChange={fnInputChange}
//                   className="p-8-10 outline-none border-grey dark-color w-full"
//                 />
//               </div>

//               <div className="d-flex flex-col align-start gap-12">
//                 <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
//                   <span className="green-color fs-18">*</span>
//                   <span className="fw-400">Phone</span>
//                 </span>
//                 <div className="position-rel w-full">
//                   <span className="position-abs t-8 l-8 fs-14 dark-color">
//                     +45
//                   </span>
//                   <input
//                     type="text"
//                     value={mobile}
//                     name="mobile"
//                     onChange={fnInputChange}
//                     className="p-8-10 pl-4 outline-none border-grey dark-color w-full"
//                   />
//                 </div>
//               </div>

//               <div className="d-flex flex-col align-start gap-12 grid-col-1-3">
//                 <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
//                   <span className="green-color fs-18">*</span>
//                   <span className="fw-400">Email</span>
//                 </span>
//                 <input
//                   type="text"
//                   value={email}
//                   disabled={true}
//                   name="email"
//                   onChange={fnInputChange}
//                   className="p-8-10 outline-none border-grey dark-color w-full"
//                 />
//               </div>

//               {/* Password Fields */}
//               {/* <div className="d-flex flex-col align-start gap-12 grid-col-1-3">
//               <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
//                 <span className="green-color fs-18">*</span>
//                 <span className="fw-400">Old Password</span>
//               </span>
//               <input
//                 type="password"
//                 value={oldPassword}
//                 name="oldPassword"
//                 onChange={fnInputChange}
//                 className="p-8-10 outline-none border-grey dark-color w-full"
//               />
//             </div>

//             <div className="d-flex flex-col align-start gap-12 grid-col-1-3">
//               <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
//                 <span className="green-color fs-18">*</span>
//                 <span className="fw-400">New Password</span>
//               </span>
//               <input
//                 type="password"
//                 value={newPassword}
//                 name="newPassword"
//                 onChange={fnInputChange}
//                 className="p-8-10 outline-none border-grey dark-color w-full"
//               />
//             </div>

//             <div className="d-flex flex-col align-start gap-12 grid-col-1-3">
//               <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
//                 <span className="green-color fs-18">*</span>
//                 <span className="fw-400">Confirm New Password</span>
//               </span>
//               <input
//                 type="password"
//                 value={confirmNewPassword}
//                 name="confirmNewPassword"
//                 onChange={fnInputChange}
//                 className="p-8-10 outline-none border-grey dark-color w-full"
//               />
//             </div> */}
//             </div>
//           )}

//           <div className="d-flex align-center gap-12">

//             <div
//               onClick={() => fnUpdateUserData()}
//               className="d-flex align-center justify-center p-10-16 green-back white-color cursor-p"
//             >
//               {loader ? <BtnLoader /> : "Submit"}

//             </div>

//           </div>

//         </div>

//       </div>

//     </div>
//   );
// };

// export default ProfilePage;