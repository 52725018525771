import { Fragment, useState } from "react";
import {
    GoogleMap,
    InfoWindowF,
    MarkerF,
    useLoadScript,
} from "@react-google-maps/api";
import { Config } from "../../constants/Index";

function GoogleMapMark({ markers }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: Config.googleMapApiKey,
    });

    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (marker) => {
        console.log(marker);
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    return (
        <Fragment>
            {isLoaded ? (
                <GoogleMap
                    center={{ lat: markers?.[0]?.position?.lat, lng: markers?.[0]?.position?.lng }}
                    zoom={12}
                    onClick={() => setActiveMarker(null)}
                    mapContainerStyle={{ width: "100%", height: "404px" }}
                >
                    {markers?.map(({ id, name, position }) => (
                        <MarkerF
                            key={id}
                            position={position}
                            onClick={() => handleActiveMarker(id)}
                        // icon={{
                        //   url:'',
                        //   scaledSize: { width: 50, height: 50 }
                        // }}
                        >
                            {activeMarker === id ? (
                                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                                    <div>
                                        <p>{name}</p>
                                    </div>
                                </InfoWindowF>
                            ) : null}
                        </MarkerF>
                    ))}

                </GoogleMap>
            ) : null}
        </Fragment>
    );
}

export default GoogleMapMark;