import React, { useEffect, useState } from "react";
import "./gdpr.css";
import { t } from "i18next";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
const Gdpr = () => {
  const [showCookieModel, setShowCookieModel] = useState(!Cookies.get("gdprConsent"));

  const fnCookie = () => {
    Cookies.set("gdprConsent", "true", {expires: 365});
    setShowCookieModel(false);
  };

  return (
    <div>
      {showCookieModel && (
        <div className={"gdpr-container"}>

          <div>
            {t("gdpr.text")}
            &nbsp;
            <a href="https://2gdpr.com/cookies" target="_blank">
              {t("gdpr.read_more")}
            </a>
            <div className="button-container">
              <Button
                className="button"
                variant="contained"
                onClick={fnCookie}
              >
                {" "}
                {t("gdpr.got_it")}
              </Button>
            </div>
          </div>

        </div>
      ) }
    </div>
  );
};
export default Gdpr;
// import React, { useEffect, useState } from "react";
// import "./gdpr.css";
// import { t } from "i18next";
// import { Button } from "@mui/material";
// import Cookies from "js-cookie";
// const Gdpr = () => {
//   const [gdpr, setGdpr] = useState(0);
//   const setCookie = () => {
//     console.log("setCookie");
//     Cookies.set("gdpr", "allow");
//     setGdpr(1);
//   };
//   useEffect(() => {
//     if (Cookies.get("gdpr")) {
//       setGdpr(1);
//     }
//   }, []);
//   return (
//     <div>
//       {/* {gdpr} */}
//       {Cookies.get("gdpr") ? (
//         <></>
//       ) : (
//         <div className={"gdpr-container"}>
//           <div>
//             {Cookies.get("gdpr")}
//             {t("gdpr.text")}
//             &nbsp;
//             <a href="https://2gdpr.com/cookies" target="_blank">
//               {t("gdpr.read_more")}
//             </a>
//             <div className="button-container">
//               <Button
//                 className="button"
//                 variant="contained"
//                 onClick={setCookie}
//               >
//                 {" "}
//                 {t("gdpr.got_it")}
//               </Button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
// export default Gdpr;
