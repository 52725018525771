import React, { useState } from "react";
import "./NavSearch.css";
import { useTranslation } from "react-i18next";
import { BtnLoader } from "../../../loaders/Loaders";

const NavSearch = ({ fnOnChange, fnSubmit, searchQuery, searchLoader }) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false); // Track input focus

  const handleButtonSubmit = () => {
    if (searchQuery) {
      fnSubmit();
    }
  };

  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      handleButtonSubmit();
    }
  };

  return (
    <div className="search__nav align-center gap-24 w-full max-inner">
      <div className="search__bar d-flex align-center sm-flex-col">
        <div
          className={`search__field d-flex space-between align-center w-full h-full ${
            isFocused ? "active" : ""
          }`}
        >
          <div className="d-flex align-center w-full">
            <input
              name="search"
              type="text"
              value={searchQuery}
              onChange={fnOnChange}
              onKeyDown={handleSearch}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder={t("Common.search_what_you_want")}
              className="border-none p-10-8 w-full outline-none"
            />
            {searchQuery && (
              <i
                onClick={() => {
                  fnSubmit(true);
                }}
                className="ri-close-line pr-1 fs-20 dark-color cursor-p hover-green transition-03"
              ></i>
            )}
          </div>
          <button
            onClick={handleButtonSubmit}
            className="search__btn p-12 fs-16 dark-color fw-500 green-back h-full white-color cursor-p"
          >
            {searchLoader ? <BtnLoader /> : t("Common.Search")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavSearch;
