import React from 'react'

const PermissionPopup = ({ title = 'Are you sure to want to remove product ?', onClickYes, onClickNo }) => {
    return (
        <div className="d-flex flex-col position-fixed t-50p l-50p w-400 gap-12 transform-50p white-back radi-4 z-1 p-16">

            <span className="fs-18 dark-color">
                {title}
            </span>

            <div className="d-flex justify-end gap-12 border-t-1-grey pt-1">

                <span onClick={onClickYes} className="fs-16 border-green p-4-12 green-color fw-500 d-flex align-center justify-center hover-white hover-green-back transition-03 cursor-p">
                    Yes
                </span>

                <span onClick={onClickNo} className="fs-16 border-grey p-4-12 dark-color fw-500 d-flex align-center justify-center cursor-p">
                    No
                </span>

            </div>

        </div>
    )
}

export default PermissionPopup