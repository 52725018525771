import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'CartReducer',
    initialState: {
        cartItems: []
    },
    reducers: {
        addToCart: (state, action) => {
            const { id, size } = action?.payload;
            const itemInCart = state?.cartItems?.find(item => item?.id == id && item?.size == size);
            if (itemInCart) { itemInCart.quantity += action.payload.quantity; }
            else { state?.cartItems?.push(action?.payload); }
        },
        removeFromCart: (state, action) => {
            const { id, size } = action?.payload;
            state.cartItems = state?.cartItems?.filter(item => !(item?.id == id && item?.size == size));
        },
        emptyCart: (state) => {
            state.cartItems = [];
        },
        incrementQuantity: (state, action) => {
            const { id, size } = action?.payload;
            const item = state?.cartItems?.find(item => item?.id == id && item?.size == size);
            item.quantity++;
        },
        decrementQuantity: (state, action) => {
            const { id, size } = action?.payload;
            const item = state?.cartItems?.find(item => item?.id == id && item?.size == size);
            if (item?.quantity == 1) { item.quantity = 1; }
            else { item.quantity-- }
        },
    },
});

export const { addToCart, removeFromCart, emptyCart, incrementQuantity, decrementQuantity } = slice.actions;

export const selectedCartSelector = (state) => state.CartReducer.cartItems;

export default slice.reducer;