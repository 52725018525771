import React from "react";
import DZText from "../../components/text/DZText";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();
  const aboutUsDetail = t("About_us.about_us_detail");
  const paragraphs = aboutUsDetail?.split('\n')?.map((paragraph, index) => (
    <p key={index} className="fs-16 dark-color">{paragraph}</p>
  ));
  return (
    <div className="d-flex flex-col align-center justify-center w-full">
      <div className="d-flex gap-24 max-inner align-center w-full p-48-0 md-flex-col">
        <div className="d-flex flex-col gap-32">
          <DZText className="fs-36 fw-600 dark-color">
            {"About_us.the_story_about_us"}
          </DZText>
          {paragraphs}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
