import React, { useState, useEffect } from "react";
import axios from "axios";
import { List, ListItemButton, ListItemText } from "@mui/material";
import "./dawa-address.css";
// import Loader from "../loader/Loader";
import InputField from "../InputField/InputField";
import { useGetRestaurantDetailsQuery, useUserAddAddressMutation } from "../../redux/storeApis";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { BtnLoader } from "../loaders/Loaders";

import RestaurantDistanceCalculator from "../restaurant-distance/RestaurantDistanceCalculator";

function DawaAutoAddress({ refetchAddressList, setUserInfo, placeholder, setAddressPopup = false }) {

  const { data: restaurantData } = useGetRestaurantDetailsQuery();

  const { fnShowSnackBar } = useSnackBarManager();

  const [query, setQuery] = useState("");
  const [checkPost, setCheckPost] = useState(false)
  const [suggestions, setSuggestions] = useState([]);
  const [userAddAddress] = useUserAddAddressMutation();
  const [data, setData] = useState({ city: "", postCode: "", street: "", lat: "", long: "" });
  const [showAddress, setShowAddress] = useState(true);
  const [loader, setLoader] = useState(false);

  const availablePostCodes = restaurantData?.data?.delivery_postcodes;

  console.log(restaurantData?.data?.delivery_postcodes);


  // console.log("asd",availablePostCodes);
  useEffect(() => {
    if (query.trim() === "") {
      setSuggestions([]);
      return;
    }
    axios
      .get(`https://dawa.aws.dk/adresser/autocomplete?q=${query}`)
      .then((response) => {
        const suggestions = response.data;
        setSuggestions(suggestions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [query]);

  const fnOnChange = (e) => {
    setQuery(e.target.value);
    setShowAddress(true);
  };

  const isDeliverInAddress = (postcodes, post_code) => {
    console.log("POST CODES",postcodes, post_code)
    const canDeliver = postcodes && postcodes?.some((delivery_postcode) => delivery_postcode?.postcode == post_code);
    return canDeliver;

  }

  const fnClickAddress = (suggestion) => {
    const address = {
      post_code: suggestion?.adresse?.postnr,
      city: suggestion?.adresse?.postnrnavn,
      lat: suggestion?.adresse?.y,
      long: suggestion?.adresse?.x,
      street: suggestion?.tekst,
    };
    setData({
      city: address.city,
      postCode: address.post_code,
      street: address.street,
      lat: address.lat,
      long: address.long,
    });
    setQuery(suggestion?.tekst);
    setShowAddress(false);
  };

  const fnAddNewAddress = () => {
  if(data.city && data.lat && data.long && data.postCode && data.street){
    setLoader(true);
    const checkAvailable = isDeliverInAddress(availablePostCodes, data.postCode);
    console.log("check available", checkAvailable)
    if (checkAvailable) {
      const addressData = {
        city: data.city,
        post_code: data.postCode,
        street: data.street,
        lat: data.lat,
        long: data.long,
      };
      userAddAddress(addressData)
        .unwrap()
        .then((response) => {
          if (response?.success) {
            // if (refetchAddressList) {  refetchAddressList(); }
            if(setUserInfo) { setUserInfo((pre) => ({ ...pre, ...addressData })); }
            setTimeout(() => {
              if (setAddressPopup) {
                setAddressPopup(false)
              };
              setQuery("");
              fnShowSnackBar('Address added successfully');
            }, 1000);
          }
          setTimeout(() => { setLoader(false); }, 1000);
        })
        .catch((error) => {
          console.error("Error adding address:", error);
          fnShowSnackBar(error?.message || "Something went wrong");
          setLoader(false);
        });
    } else {
      setTimeout(() => {
        setLoader(false);
        fnShowSnackBar('We cannot deliver on this post code, please change your address!');
      }, 1000);
      setCheckPost(true)
    }
  }else{
    fnShowSnackBar("Please search for an address to add!")
  }
  };


  return (
    <>
      <span className="fs-28 fw-500 dark-color">
        Add Address
      </span>

      <div className="w-full d-grid grid-colt-2 gap-24 gap-r-12 sm-d-flex flex-col">

        <div className="d-flex flex-col align-start gap-12 grid-col-1-3">

          <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
            <span className='green-color fs-18'>*</span>
            <span className='fw-400'>Search for an address to add</span>
          </span>

          <div className="address__search w-full">

            <InputField
              darkColor={true}
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              placeholder={placeholder}
              value={query}
              onChange={fnOnChange}
            />

            {suggestions.length > 0 && showAddress && (
              <List
                style={{
                  position: "absolute",
                  maxHeight: "250px",
                  overflow: "scroll",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)"
                }}
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <ListItemButton onClick={() => fnClickAddress(suggestion)}>
                    <ListItemText key={index} primary={suggestion.tekst} />
                  </ListItemButton>
                ))}
              </List>
            )}
          </div>

        </div>

        <div className="d-flex flex-col align-start gap-12">

          <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
            <span className='green-color fs-18'>*</span>
            <span className='fw-400'>Street</span>
          </span>

          <input type="text" value={data?.street} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

        </div>

        <div className="d-flex flex-col align-start gap-12">

          <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
            <span className='green-color fs-18'>*</span>
            <span className='fw-400'>City</span>
          </span>

          <input type="text" value={data?.city} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

        </div>

        <div className="d-flex flex-col align-start gap-12 grid-col-1-3">

          <span className='fs-14 fw-600 dark-color d-flex align-center gap-4'>
            <span className='green-color fs-18'>*</span>
            <span className='fw-400'>Postcode</span>
          </span>

          <input type="text" value={data?.postCode} disabled={true} className='p-8-10 outline-none border-grey dark-color w-full' />

        </div>

      </div>
      
      {/* { checkPost && <RestaurantDistanceCalculator restaurant={restaurantData} userAddress={ data }/> } */}

      <div className="d-flex align-center gap-12">
        <div onClick={() => setAddressPopup(false)} className="d-flex align-center justify-center p-10-16 border-grey dark-color cursor-p">
          Cancel
        </div>

        <div onClick={() => fnAddNewAddress()} className="green-back d-flex align-center justify-center p-10-16 white-color cursor-p">
          {loader ? <BtnLoader loaderStyle={{ height: "25px", paddingLeft: "16px", paddingRight: "16px", }} /> : 'Submit'}
        </div>

      </div>

    </>
  );
}

export default DawaAutoAddress;
