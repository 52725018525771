import Lottie from "react-lottie";
import NoProductAnimation from '../../animations/no_product.json'
import NoAddressAnimation from '../../animations/no_address.json'
import NoOrderAnimation from '../../animations/no_order.json'
import ComingSoonAnimation from '../../animations/coming_soon.json'

const Loader = ({ className, svgClassName }) => {
    return (
        <div style={{ height: '600px' }} className={className ?? 'w-full d-flex align-center justify-center'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 300 300" fill="none" className={`rotating__loader ${svgClassName ?? 'w-200 h-200'}`}>
                <g filter="url(#filter0_d_4_79)">
                    <path d="M232 146C232 191.287 195.287 228 150 228C104.713 228 68 191.287 68 146C68 100.713 104.713 64 150 64C195.287 64 232 100.713 232 146ZM87.5465 146C87.5465 180.492 115.508 208.454 150 208.454C184.492 208.454 212.454 180.492 212.454 146C212.454 111.508 184.492 83.5465 150 83.5465C115.508 83.5465 87.5465 111.508 87.5465 146Z" fill="#EBF6E1" />
                    <path d="M95.4969 193.393C91.4238 196.935 85.1952 196.532 82.1629 192.067C74.8307 181.269 70.1542 168.835 68.5858 155.784C66.6271 139.485 69.6056 122.973 77.1357 108.386C84.6658 93.7991 96.4009 81.8079 110.822 73.9646C125.243 66.1214 141.687 62.7872 158.024 64.3935C174.361 65.9999 189.84 72.4729 202.458 82.9748C215.075 93.4766 224.25 107.524 228.795 123.298C233.34 139.073 233.045 155.848 227.95 171.453C223.869 183.949 216.86 195.234 207.566 204.397C203.722 208.186 197.534 207.368 194.229 203.101V203.101C190.923 198.834 191.772 192.741 195.472 188.811C201.744 182.149 206.504 174.159 209.369 165.386C213.25 153.501 213.474 140.724 210.012 128.71C206.551 116.695 199.563 105.997 189.953 97.9982C180.344 89.9997 168.554 85.0697 156.111 83.8462C143.668 82.6228 131.145 85.1622 120.161 91.1358C109.177 97.1095 100.24 106.242 94.5045 117.352C88.7693 128.462 86.5008 141.038 87.9926 153.452C89.0938 162.615 92.2065 171.378 97.0608 179.134C99.9244 183.71 99.57 189.851 95.4969 193.393V193.393Z" fill="#87C74A" />
                </g>
                <defs>
                    <filter id="filter0_d_4_79" x="0.800003" y="0.800003" width="298.4" height="298.4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="33.6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.529412 0 0 0 0 0.780392 0 0 0 0 0.290196 0 0 0 0.2 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_79" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_79" result="shape" />
                    </filter>
                </defs>
            </svg>
        </div>
    )
};

const BtnLoader = ({ loaderStyle }) => {
    const mergeStyles = { height: "22px", paddingLeft: "12px", paddingRight: "12px", ...loaderStyle }
    return (
        <img
            style={mergeStyles}
            src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
            alt="noImg"
        />
    )
};

const NoProductLoader = ({ loaderStyle, containerClassName }) => {
    const mergeStyles = { height: "250px", width: "250px", ...loaderStyle }
    return (
        <div className={`d-flex w-full justify-center align-center h-5vh ${containerClassName}`}>
            <Lottie options={{ animationData: NoProductAnimation }} style={mergeStyles} />
        </div>
    )
};

const NoAddressLoader = ({ loaderStyle, containerClassName }) => {
    const mergeStyles = { height: "250px", width: "250px", ...loaderStyle }
    return (
        <div className={`d-flex w-full justify-center align-center h-4vh ${containerClassName}`}>
            <Lottie options={{ animationData: NoAddressAnimation }} style={mergeStyles} />
        </div>
    )
};

const NoOrderLoader = ({ loaderStyle, containerClassName }) => {
    const mergeStyles = { height: "250px", width: "250px", ...loaderStyle }
    return (
        <div className={`d-flex w-full justify-center align-center h-4vh ${containerClassName}`}>
            <Lottie options={{ animationData: NoOrderAnimation }} style={mergeStyles} />
        </div>
    )
};

const ComingSoonLoader = ({ loaderStyle, containerClassName }) => {
    const mergeStyles = { height: "300px", width: "300px", ...loaderStyle }
    return (
        <div className={`d-flex w-full justify-center align-center h-4vh ${containerClassName}`}>
            <Lottie options={{ animationData: ComingSoonAnimation }} style={mergeStyles} />
        </div>
    )
}


export { Loader, BtnLoader, NoProductLoader, NoAddressLoader, NoOrderLoader, ComingSoonLoader };