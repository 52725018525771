import React, { useEffect, useState } from 'react'
import { useChangePasswordMutation, useUserUpdateDataMutation } from '../../redux/storeApis';
import { useUserManager } from '../../lib/customHooks/useUserManager';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { BtnLoader, Loader } from '../loaders/Loaders';

const ViewProfile = () => {
    const { fnShowSnackBar } = useSnackBarManager();
    const { userName, userEmail, userMobile, isSuccessUser, isLoadingUser } = useUserManager();

    const [updateUserInfo] = useUserUpdateDataMutation();
    const [changePassword] = useChangePasswordMutation();

    const [userData, setUserData] = useState({
        name: "",
        email: "",
        mobile: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

    const [loader, setLoader] = useState(false);

    const { name, email, mobile, oldPassword, newPassword, confirmNewPassword } = userData;

    useEffect(() =>  { 
        setUserData({ ...userData, name: userName, email: userEmail, mobile: userMobile, }); 
    }, [isSuccessUser, userEmail, userName, userMobile]);

    const fnInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const fnUpdateUserData = () => {
        if (userName != name || userMobile != mobile) {
            setLoader(true);
            updateUserInfo({ name: name, mobile: mobile, })
                .unwrap()
                .then((res) => {
                    console.log(res);
                    fnShowSnackBar("User data updated");
                    setLoader(false);
                    // if (oldPassword && newPassword && confirmNewPassword) {
                    //   fnChangePassword();
                    // } else {
                    //   fnShowSnackBar("User data updated");
                    //   setLoader(false);
                    // }
                })
                .catch((err) => {
                    setLoader(false);
                    fnShowSnackBar('Some error occurred');
                });
        }
    };

    const fnChangePassword = () => {
        if (newPassword == confirmNewPassword) {
            changePassword({
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword,
            })
                .unwrap()
                .then((res) => {
                    console.log(res);
                    setLoader(false);
                    fnShowSnackBar('User Data Updated')
                })
                .catch((err) => console.log(err));
        } else {
            fnShowSnackBar('Password not match!');
            setLoader(false);
        }
    };

    return (
        <div className="d-flex gap-24 w-full max-inner min-h-10vh">

            <div className="d-flex align-center flex-col gap-24 w-full">

                <span className="fs-24 fw-600 dark-color">Profile</span>

                {isLoadingUser ? (
                    <Loader svgClassName={"w-60 h-60"} loaderContainerStyle={{ height: '300px' }} />
                ) : (
                    <div className="w-full d-grid grid-colt-2 gap-24 gap-r-12 sm-d-flex flex-col">
                        <div className="d-flex flex-col align-start gap-12">
                            <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
                                <span className="green-color fs-18">*</span>
                                <span className="fw-400">Name</span>
                            </span>
                            <input
                                type="text"
                                value={name}
                                name="name"
                                onChange={fnInputChange}
                                className="p-8-10 outline-none border-grey dark-color w-full"
                            />
                        </div>

                        <div className="d-flex flex-col align-start gap-12">
                            <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
                                <span className="green-color fs-18">*</span>
                                <span className="fw-400">Phone</span>
                            </span>
                            <div className="position-rel w-full">
                                <span className="position-abs t-8 l-8 fs-14 dark-color">
                                    +45
                                </span>
                                <input
                                    type="text"
                                    value={mobile}
                                    name="mobile"
                                    onChange={fnInputChange}
                                    className="p-8-10 pl-4 outline-none border-grey dark-color w-full"
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-col align-start gap-12 grid-col-1-3">
                            <span className="fs-14 fw-600 dark-color d-flex align-center gap-4">
                                <span className="green-color fs-18">*</span>
                                <span className="fw-400">Email</span>
                            </span>
                            <input
                                type="text"
                                value={email}
                                disabled={true}
                                name="email"
                                onChange={fnInputChange}
                                className="p-8-10 outline-none border-grey dark-color w-full"
                            />
                        </div>
                    </div>
                )}

                <div className="d-flex align-center gap-12">

                    <div
                        onClick={() => fnUpdateUserData()}
                        className="d-flex align-center justify-center p-10-16 green-back white-color cursor-p"
                    >
                        {loader ? <BtnLoader /> : "Submit"}

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ViewProfile;