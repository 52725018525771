import React, { useEffect, useState } from "react";
import { Config } from "../../constants/Index";
import InputField from "../InputField/InputField";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { BtnLoader } from "../loaders/Loaders";

const NewPasswordPopup = ({
  setNewPasswordPopup = true,
  setLoginPopup = false,
  userEmail = false,
  userVerificationCode = false,
}) => {
  const { fnShowSnackBar } = useSnackBarManager();

  const [data, setData] = useState({ newPassword: "", confirmPassword: "" });
  const [loader, setLoader] = useState(false);
  const [passwordLengthValid, setPasswordLengthValid] = useState(true);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClosePopups();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function handleOpenPopups() {
    setNewPasswordPopup(false);
    setLoginPopup(true);
  }

  function handleClosePopups() {
    setNewPasswordPopup(false);
    setLoginPopup(true);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
    setPasswordLengthValid(value.length >= 6);
  };

  const handleSetNewPassword = async (e) => {
    e.preventDefault();
    if (data.newPassword !== data.confirmPassword) {
      fnShowSnackBar("Passwords do not match");
      return;
    }

    if (!passwordLengthValid) {
      fnShowSnackBar("Password should be at least 6 characters long");
      return;
    }

    setLoader(true);
    try {
      const response = await fetch(`${Config.serverUrl}api/new-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userEmail,
          verification_code: userVerificationCode,
          password: data.newPassword,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          fnShowSnackBar(responseData.message);
          handleOpenPopups();
        } else {
          fnShowSnackBar(responseData.message || "Something went wrong");
        }
      } else {
        fnShowSnackBar("Something went wrong");
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      fnShowSnackBar("Something went wrong");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="d-flex flex-col align-center gap-24 position-r p-12 sm-max-w-300">
      <i
        onClick={() => handleClosePopups()}
        className="fa-solid fs-20 dark-color fa-xmark position-abs r-20 transition-03 hover-green cursor-p t-20"
      ></i>

      <h2 className="fs-32 fw-500 dark-color">Set New Password</h2>

      <div className="d-flex align-start flex-col gap-12 w-420 sm-max-w-300 w-full">
        <span className="fs-14 dark-color">
          New Password
          <span className="fs-14 green-color fw-500">*</span>
        </span>
        <InputField
          placeholder="Enter new password"
          type="password"
          onChange={handleInputChange}
          name="newPassword"
          value={data.newPassword}
        />
      </div>

      <div className="d-flex align-start flex-col gap-12 w-420 sm-max-w-300 w-full">
        <span className="fs-14 dark-color">
          Confirm Password
          <span className="fs-14 green-color fw-500">*</span>
        </span>
        <InputField
          placeholder="Confirm new password"
          type="password"
          onChange={handleInputChange}
          name="confirmPassword"
          value={data.confirmPassword}
        />
      </div>

      <span
        onClick={handleSetNewPassword}
        className="d-flex p-8-12 green-back white-color cursor-p align-center justify-center w-full"
      >
        {loader ? <BtnLoader /> : "Set New Password"}
      </span>
    </div>
  );
};

export default NewPasswordPopup;
