import React, { useEffect, useState } from 'react'
import PermissionPopup from '../popups/PermissionPopup';
import Popup from '../popup/Popup';
import LoginPopup from '../popups/LoginPopup';
import RegisterPopup from '../registerPopup/RegisterPopup';
import ForgetPasswordPopup from '../popups/ForgetPasswordPopup';
import ConfirmationCodePopup from '../popups/ConfirmationCodePopup';
import NewPasswordPopup from '../popups/NewPasswordPopup';
import { removeLocalStorage } from '../localStorage';
import { Config } from '../../constants/Index';
import { setSelectedLoginUser } from '../../redux/UserReducer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useUserManager } from '../../lib/customHooks/useUserManager';

const ViewPopUps = ({ handlePopUps }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedInUser } = useUserManager();

    const [permissionPopup, setPermissionPopup] = useState({ status: false, title: "", });
    const [loginPopup, setLoginPopup] = useState(false);
    const [registerPopup, setRegisterPopup] = useState(false);
    const [forgetPasswordPopup, setForgetPasswordPopup] = useState(false);
    const [confirmationCodePopup, setConfirmationCodePopup] = useState(false);
    const [newPasswordPopup, setNewPasswordPopup] = useState(false);

    const [userEmail, setUserEmail] = useState("");
    const [userVerificationCode, setUserVerificationCode] = useState("");

    useEffect(() => { if (handlePopUps) { fnCheckLoginStatus() } }, [handlePopUps]);

    const fnCheckLoginStatus = () => {
        if (isLoggedInUser) { setPermissionPopup({ status: true, title: "Are you sure you want to logout?", }); }
        else { setLoginPopup(true); }
    };

    const fnLogoutUser = () => {
        removeLocalStorage(Config.userToken);
        dispatch(setSelectedLoginUser(null));
        setPermissionPopup({ status: false, title: "" });
        navigate("/");
    };

    const fnLogout = async () => {
        try {
            const response = await fetch(`${Config.serverUrl}api/logout`, {
                method: "GET",
                headers: { Authorization: `Bearer ${isLoggedInUser}` },
            });
            if (response?.status === 200) {
                fnLogoutUser();
            }
        } catch (error) {
            fnLogoutUser();
        } finally {
            fnLogoutUser();
        }
    };

    return (
        <>
            {permissionPopup.status && (<Popup onClose={()=>setPermissionPopup(false)} children={<PermissionPopup title={permissionPopup.title} onClickNo={() => setPermissionPopup({ status: false, title: "" })} onClickYes={() => fnLogout()} />} />)}
            {loginPopup && (
                <Popup onClose={()=>setLoginPopup(false)} children={<LoginPopup setLoginPopup={setLoginPopup} setRegisterPopup={setRegisterPopup} setForgetPasswordPopup={setForgetPasswordPopup} />} />
            )}
            {registerPopup && (
                <Popup onClose={()=>setRegisterPopup(false)} children={<RegisterPopup setRegisterPopup={setRegisterPopup} setLoginPopup={setLoginPopup} />} />
            )}
            {forgetPasswordPopup && (
                <Popup onClose={()=>setForgetPasswordPopup(false)} children={<ForgetPasswordPopup setLoginPopup={setLoginPopup} setForgetPasswordPopup={setForgetPasswordPopup} setConfirmationCodePopup={setConfirmationCodePopup} setUserEmail={setUserEmail} />} />
            )}
            {confirmationCodePopup && (
                <Popup onClose={()=>(setConfirmationCodePopup(false))} children={<ConfirmationCodePopup setLoginPopup={setLoginPopup} setForgetPasswordPopup={setForgetPasswordPopup} setConfirmationCodePopup={setConfirmationCodePopup} setNewPasswordPopup={setNewPasswordPopup} userEmail={userEmail} setUserVerificationCode={setUserVerificationCode} />} />
            )}
            {newPasswordPopup && (
                <Popup onClose={()=>(setNewPasswordPopup(false))} children={<NewPasswordPopup setNewPasswordPopup={setNewPasswordPopup} setConfirmationCodePopup={setConfirmationCodePopup}  setLoginPopup={setLoginPopup}  userEmail={userEmail} userVerificationCode={userVerificationCode} />} />
            )}
        </>
    )
};

export default ViewPopUps;