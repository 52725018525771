import { useGetRestaurantDetailsQuery } from "../../redux/storeApis";

export function useLinksManager() {
    const { data: restaurantDetails, isLoading: isLoadingRestaurantDetails, isSuccess: isSuccessRestaurantDetails } = useGetRestaurantDetailsQuery();

    const restaurant_phone = restaurantDetails?.data?.phone;
    const restaurant_email = restaurantDetails?.data?.email;
    const restaurant_address = restaurantDetails?.data?.address;

    const restaurant_long = restaurantDetails?.data?.long;
    const restaurant_lat = restaurantDetails?.data?.lat;

    const twitter = restaurantDetails?.data?.restaurant_link?.twitter;
    const linkedin = restaurantDetails?.data?.restaurant_link?.linkedin;
    const facebook = restaurantDetails?.data?.restaurant_link?.facebook;
    const instagram = restaurantDetails?.data?.restaurant_link?.instagram;

    const twitterLink = twitter && `${twitter}`;
    const linkedInLink = linkedin && `${linkedin}`;
    const faceBookLink = facebook && `${facebook}`;
    const instagramLink = instagram && `${instagram}`;

    const opening_hours = restaurantDetails?.data?.opening_hours;

    return {
        restaurantDetails,

        restaurant_phone,
        restaurant_email,
        restaurant_address,

        restaurant_lat,
        restaurant_long,

        twitterLink,
        linkedInLink,
        faceBookLink,
        instagramLink,

        isLoadingRestaurantDetails,
        isSuccessRestaurantDetails,

        opening_hours

    }
}