import React from 'react'
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';

const SnackBar = () => {

    const { isShowSnackBar, fnHideSnackBar } = useSnackBarManager();

    // setTimeout(() => { if (setShowSnackBar) { setShowSnackBar(false); } }, 3000);

    return (
        <>
            {isShowSnackBar && <div className="d-flex align-center gap-12 p-10-16 radi-4 white-back position-fixed shadow-lg r-20 t-40 z-9">
                <i onClick={() => fnHideSnackBar()} className="fa-solid fa-circle-xmark dark-color fs-20 cursor-p hover-green transition-03"></i>
                <span className='dark-color fs-14 fw-500 xxsm-max-w-260'>
                    {isShowSnackBar}
                </span>
            </div>}
        </>
    )
}

export default SnackBar;
// import React from 'react'

// const SnackBar = ({ title, setShowSnackBar = false }) => {

//     setTimeout(() => { if (setShowSnackBar) { setShowSnackBar(false); } }, 3000);

//     return (
//         <div className="d-flex align-center gap-12 p-10-16 radi-4 white-back position-fixed shadow-lg r-20 t-40 z-9">
//             <i onClick={() => setShowSnackBar(false)} className="fa-solid fa-circle-xmark dark-color fs-20 cursor-p hover-green transition-03"></i>
//             <span className='dark-color fs-14 fw-500 xxsm-max-w-260'>
//                 {title ?? ''}
//             </span>
//         </div>
//     )
// }

// export default SnackBar;