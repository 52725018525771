import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../reactRoute/RouteConstants';
import { useUserManager } from '../../../../lib/customHooks/useUserManager';
import { removeLocalStorage } from '../../../localStorage';
import { Config } from '../../../../constants/Index';
import { KEYS, LANGUAGES } from "../../../../constants/Index";
import { getLocalStorage, setLocalStorage, } from "../../../localStorage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { selectedLoginUser, } from "../../../../redux/UserReducer";
import { useLinksManager } from "../../../../lib/customHooks/useLinksManager";
import { setSelectedLoginUser } from '../../../../redux/UserReducer';
import LoginPopup from '../../../popups/LoginPopup';
import Popup from '../../../popup/Popup';
import PermissionPopup from '../../../popups/PermissionPopup';
import DZText from '../../../text/DZText';

const MobileNavBar = ({ setMobileNavBarClass,  }) => {

    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { isLoggedInUser } = useUserManager();

    const popupRef = useRef(null);
    const [permissionPopup, setPermissionPopup] = useState({ status: false, title: "", });
    const [loginPopup, setLoginPopup] = useState(false);
    const [registerPopup, setRegisterPopup] = useState(false);
    const [forgetPasswordPopup, setForgetPasswordPopup] = useState(false);
    const [openLangDrop, setOpenLangDrop] = useState(false);
    const selectedLanguage = getLocalStorage(KEYS.lang);

    useEffect(() => {
        if(openLangDrop) {
         const handleOutsideClick = (event) => {
             if (popupRef.current && !popupRef.current.contains(event.target)) { setOpenLangDrop(false); }
         };
         document.addEventListener('mousedown', handleOutsideClick);
 
         return () => { document.removeEventListener('mousedown', handleOutsideClick); };
        }
     }, [openLangDrop]);

    const fnCheckLoginStatus = () => {
        if (isLoggedInUser) {
            setPermissionPopup({ status: true, title: "Are you sure you want to logout?", });
        } else {
            setLoginPopup(true);
        }
    };

    const fnLogoutUser = () => {
        removeLocalStorage(Config.userToken);
        dispatch(setSelectedLoginUser(null));
        setPermissionPopup({ status: false, title: "" });
        setMobileNavBarClass('d-none');
        navigate("/");
    };

    const fnLogout = async () => {
        try {
            const response = await fetch(`${Config.serverUrl}api/logout`, {
                method: "GET",
                headers: { Authorization: `Bearer ${isLoggedInUser}` },
            });
            if (response?.status === 200) {
                fnLogoutUser();
            }
        } catch (error) {
            fnLogoutUser();
        } finally {
            fnLogoutUser();
        }
    };

    const fnNavigateToSelectedTab = (route) => {
        setMobileNavBarClass('d-none');
        navigate(route);
    };

    const fnSelectedTab = (route) => {
        return location.pathname == route ? 'fs-16 green-color fw-600 cursor-p hover-green transition-03 w-fit'
            : 'fs-16 dark-color fw-600 cursor-p hover-green transition-03 w-fit'
    };

    const fnToggleLangDrop = () => {
        setOpenLangDrop(!openLangDrop);
    };

    const fnChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLocalStorage(KEYS.lang, lang);
    };

    return (
        <>
            <div className="d-flex flex-col gap-32 w-full position-fixed white-back h-10vh z-2 space-between">

                <span onClick={() => setMobileNavBarClass('d-none')} className="cursor-p fs-16 d-flex align-center dark-color gap-12 opacity-06 p-4-2 w-full border-b-1-grey pt-2 pl-2 pb-2 transition-03 hover-green">
                    <i className="fa-solid fa-xmark fs-24"></i>
                    Close
                </span>

                <div className="d-flex flex-col gap-24 pl-2">

                    <span onClick={() => fnNavigateToSelectedTab(ROUTES.home)} className={fnSelectedTab((ROUTES.home))}>
                        <DZText children={"Link_Tabs.home"} />
                    </span>

                    <span onClick={() => fnNavigateToSelectedTab(ROUTES.shop)} className={fnSelectedTab(ROUTES.shop)}>
                        <DZText children={"Link_Tabs.shop"} />
                    </span>

                    <span onClick={() => fnNavigateToSelectedTab(ROUTES.services)} className={fnSelectedTab(ROUTES.services)}>
                        <DZText children={"Link_Tabs.services"} />
                    </span>

                    <span onClick={() => fnNavigateToSelectedTab(ROUTES.contact)} className={fnSelectedTab(ROUTES.contact)}>
                        <DZText children={"Link_Tabs.contact_us"} />
                    </span>

                    <span onClick={() => fnNavigateToSelectedTab(ROUTES.about)} className={fnSelectedTab(ROUTES.about)}>
                        <DZText children={"Link_Tabs.about_us"} />
                    </span>

                    <span onClick={() => fnNavigateToSelectedTab(ROUTES.cart)} className={fnSelectedTab(ROUTES.cart)}>
                        <DZText children={"Link_Tabs.cart"} />
                    </span>

                    {/* <div className="d-flex flex-col gap-12 position-rel">

                        <span onClick={fnToggleLangDrop} style={{ color: openLangDrop ? 'var(--green-color)' : 'var(--dark-color)' }} className="fs-16 fw-600 cursor-p hover-green transition-03">
                            <DZText children={"Common.language"} />
                        </span>

                        {openLangDrop &&
                            <div ref={popupRef} className="d-flex flex-col gap-12 min-w-200 position-abs p-20 shadow-lg radi-10 z-3 white-back" style={{ top: '40px' }}>

                                <span onClose={()=>setOpenLangDrop(false)} onClick={() => fnChangeLanguage(LANGUAGES.en)} style={{ color: selectedLanguage == LANGUAGES.en ? 'var(--green-color)' : 'var(--dark-color)' }} className="fs-16 dark-color fw-600 cursor-p hover-green transition-03">
                                    English
                                </span>

                                <span onClose={()=>setOpenLangDrop(false)} onClick={() => fnChangeLanguage(LANGUAGES.da)} style={{ color: selectedLanguage == LANGUAGES.da ? 'var(--green-color)' : 'var(--dark-color)' }} className="fs-16 dark-color fw-600 cursor-p hover-green transition-03">
                                    Danish
                                </span>

                            </div>
                        }

                    </div> */}

                    <span onClick={() => fnCheckLoginStatus()} className={'fs-16 dark-color fw-600 cursor-p hover-green transition-03 w-fit'}>
                        {isLoggedInUser ? 'Logout' : 'Login'}
                    </span>


                </div>

                <span className='h-2vh'></span>

            </div>
            {loginPopup && (
                <Popup
                onClose={()=>(setLoginPopup(false))}
                    children={<LoginPopup
                        setLoginPopup={setLoginPopup}
                        setRegisterPopup={setRegisterPopup}
                        setForgetPasswordPopup={setForgetPasswordPopup}
                        setMobileNavBarClass={setMobileNavBarClass}
                    />}
                />
            )}
            {permissionPopup.status && (
                <Popup
                onClose={()=>(setPermissionPopup(false))}
                    children={
                        <PermissionPopup
                            title={permissionPopup.title}
                            onClickNo={() => setPermissionPopup({ status: false, title: "" })}
                            onClickYes={() => fnLogout()}
                        />
                    }
                />
            )}
        </>
    )
}

export default MobileNavBar