import React from "react";
import "./navbar.css";
import { PopupProvider } from "../../context/PopupContext.jsx";
import TopNavbar from "./Components/TopBar/TopBar.jsx";
import LinkNavBar from "./Components/LinkNavBar/LinkNavBar.jsx";

const Navbar = () => {
  return (
    <PopupProvider>
      <div className="nav__bar d-flex flex-col align-center mb-2">
        <TopNavbar />
        <LinkNavBar />
      </div>
    </PopupProvider>
  );
};

export default Navbar;
