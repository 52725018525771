import React from "react";

import SingleBlogPage from "../singleBlogPage/SingleBlogPage";

const BlogPage = () => {
  return (
    <div className="d-flex align-center justify-center">
      <div className="d-flex align-start gap-24 w-full max-inner sm-flex-col">
        {/* Blog List */}

        {/* <div className="d-flex flex-col gap-32 min-w-230 max-w-230 h-fit sm-max-w-full sm-flex-row sm-overflow-scroll">
          <div className="d-flex flex-col gap-24 h-fit sm-w-min-fit">
            <span className="fs-24 fw-600 dark-color w-full border-b-1-grey pb-1">
              Categories
            </span>

            <div className="d-flex flex-col gap-16 sm-flex-row">
              <span className="fs-16 dark-color sm-w-min-fit">
                All Departments
              </span>

              <span className="fs-16 dark-color sm-w-min-fit">Healthy</span>

              <span className="fs-16 dark-color sm-w-min-fit">
                Nutrition Meal
              </span>

              <span className="fs-16 dark-color sm-w-min-fit">
                Organic Planting
              </span>

              <span className="fs-16 dark-color sm-w-min-fit">Recipes</span>
            </div>
          </div>

          <div className="d-flex flex-col gap-24 sm-w-min-fit">
            <span className="fs-24 fw-600 dark-color w-full border-b-1-grey pb-1">
              Popular tags
            </span>

            <div className="d-flex flex-wrap gap-16 sm-flex-nowrap">
              <span className="fs-14 white-color p-4-8 green-back w-fit sm-w-min-fit">
                All Tags
              </span>

              <span className="fs-14 dark-color p-4-8 light-back w-fit sm-w-min-fit">
                Fresh
              </span>

              <span className="fs-14 dark-color p-4-8 light-back w-fit sm-w-min-fit">
                Vegitables
              </span>

              <span className="fs-14 dark-color p-4-8 light-back w-fit sm-w-min-fit">
                Meat
              </span>
            </div>
          </div>
        </div> */}

        {/* All Blogs */}

        <div className="d-flex flex-col gap-24">
          <SingleBlogPage />

          <div className="d-flex align-start gap-24 sm-flex-col">
            <div className="position-rel min-w-200 overflow-h h-200">
              <img
                src="https://images.unsplash.com/photo-1482049016688-2d3e1b311543?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=653&q=80"
                alt=""
                className="position-abs w-full"
              />
            </div>

            <div className="d-flex flex-col gap-16">
              <span className="p-4-10 radi-2 fs-14 fw-600 white-color w-fit green-back">
                Healthy
              </span>

              <span className="fs-26 fw-600 dark-color max-w-600">
                Potatoes 101: All You Need to Know About Common Spuds
              </span>

              <div className="d-flex align-center gap-12">
                <span className="fs-12 dark-color opacity-06">
                  Sep 09, 2020
                </span>

                <span className="fs-12 dark-color opacity-06">|</span>

                <span className="fs-12 dark-color opacity-06">3 Comments</span>
              </div>

              <p className="fs-14 dark-color">
                Russets, fingerlings, Yukon Golds, and more: learn how to
                identify different potato varieties and find out what dishes
                they're best ...
              </p>

              <span className="d-flex align-center gap-8 fs-16 dark-color fw-600">
                Read More
                <i className="fa-solid fa-arrow-right green-color"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
