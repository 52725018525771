import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { getLocalStorage } from '../components/localStorage';
import { KEYS } from '../constants/Index';
const Languages = ["da", "ar", "tr", "en"];
const selectedLanguage = getLocalStorage(KEYS.lang) ?? 'da';
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        lng: selectedLanguage,
        debug: true,
        whitelist: Languages,

        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;