import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { onlineStoreApi } from './storeApis';
import SnackBarReducer from './SnackBarReducer';
import CartReducer from './CartReducer';
import UserReducer from './UserReducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";

const snackBarReducer = 'SnackBarReducer';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [onlineStoreApi.reducerPath, snackBarReducer],
};
const appReducer = combineReducers({
    SnackBarReducer,
    CartReducer,
    UserReducer,
    [onlineStoreApi.reducerPath]: onlineStoreApi.reducer
});

const rootReducer = (state, action) => {
    if (action.type == 'logout') {
        console.log('logged out from store')
        state = undefined;
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(onlineStoreApi.middleware),
})

export const persistor = persistStore(store);
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)