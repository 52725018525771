import { useSelector } from "react-redux";
import { useGetUserDataQuery } from "../../redux/storeApis"
import { selectedLoginUser } from "../../redux/UserReducer";

export function useUserManager() {

    const { data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser, refetch: refetchUser } = useGetUserDataQuery();

    const userDetails = userData?.data?.user;
    const userName = userDetails?.name;
    const userEmail = userDetails?.email;
    const userMobile = userDetails?.mobile;

    const isLoggedInUser = useSelector(selectedLoginUser);

    return {
        userDetails,
        userName,
        userEmail,
        userMobile,
        isLoggedInUser,

        isLoadingUser,
        isSuccessUser,

        refetchUser

    }
}