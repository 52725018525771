import React, { useState, useEffect } from "react";

const TopArrow = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = (event) => {
    event.preventDefault();

    const start = window.pageYOffset;
    const destination = 0;
    const duration = 900; // 500ms
    let startTime = null;

    const animation = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = (currentTime - startTime) / duration;
      const easeProgress = easeOutQuad(progress);
      window.scrollTo(0, start + (destination - start) * easeProgress);

      if (progress < 1) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  const easeOutQuad = (t) => {
    return t * (2 - t);
  };

  return (
    <>
      {showScrollButton && (
        <div
          className="d-flex align-center gap-12 position-fixed b-20 r-20 cursor-pointer dark-back p-12 full-radi"
          onClick={handleScrollToTop}
          style={{
            transition: "opacity 0.3s ease-in-out",
            opacity: 1,
          }}
        >
          <i className="fa-solid fa-arrow-up-from-bracket white-color fs-20">
            <path d="M12 12h10v10h-10z" />
          </i>
        </div>
      )}
    </>
  );
};

export default TopArrow;
