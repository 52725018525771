import { useState } from "react"
import { setLocalStorage } from "../../components/localStorage";
import { KEYS } from "../../constants/Index";
import { addToCart } from "../../redux/CartReducer";
import { useDispatch } from "react-redux";

export function useAddToCartManager() {

    const dispatch = useDispatch();
    const [isShowPopUp, setIsShowPopUp] = useState(false);

    const fnSetProductDetail = (item) => {
        setLocalStorage(KEYS.prod_popup, JSON.stringify(item));
        setIsShowPopUp(true);
    };

    const fnAddToCart = (prod, quantity) => {
        dispatch(addToCart({ ...prod, quantity: quantity ?? 1 }));
    };

    return {
        isShowPopUp,

        setIsShowPopUp,

        fnSetProductDetail,
        fnAddToCart,
    }
}