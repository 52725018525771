import React from 'react';


import { useGetAllDealsQuery, useGetCategoriesQuery } from '../../redux/storeApis'
import { Config } from '../../constants/Index';

import { useAddToCartManager } from '../../lib/customHooks/useAddToCartManager';
// import NoProduct from '../Home/components/noProduct/NoProduct';

const WishlistPage = () => {

    const { data: allDeals } = useGetAllDealsQuery();
    const { data: allCategories } = useGetCategoriesQuery();

    const { isShowPopUp, setIsShowPopUp, fnSetProductDetail } = useAddToCartManager();

    return (
        <div className='d-flex flex-col align-center justify-centers'>

            <div className="max-inner d-flex flex-col gap-24 p-32-0 w-full">

                {/* <NoProduct /> */}

                <div className="product__cards w-full d-grid grid-colt-auto-226">

                    {allDeals?.map((deal, index) => {

                        const prodName = deal?.title?.en;
                        const prodPrice = `$${deal?.price}`;
                        const imgUrl = Config.getProductImage(deal?.image);
                        const categoryName = allCategories?.find((category) => category?.id == deal?.category_id)?.name?.en;

                        return (
                            <div key={index} className="d-flex flex-col align-center justify-center gap-2 product__card p-12 w-full">

                                <div className="w-full h-200 mb-1 position-rel overflow-h">
                                    <img src={imgUrl} className='position-abs w-full' alt="" />
                                </div>

                                <span className="fw-600 fs-16 green-color"> {categoryName} </span>

                                <span className="fw-400 fs-24 dark-color product__name"> {prodName} </span>

                                <span className="fw-600 fs-18 dark-color price__span">
                                    {prodPrice} <span className='fs-12' style={{ textDecoration: 'line-through', color: '#999' }}>
                                        $35
                                    </span>
                                </span>

                                <div className="d-flex align-center justify-center gap-12 icon__container">
                                    <i className="fa-solid fa-cart-shopping dark-color fs-14 light-back p-8 full-radi cursor-p hover-green-back transition-03 hover-white"></i>
                                    <i onClick={() => fnSetProductDetail(deal)} className="fa-solid fa-eye dark-color fs-14 light-back p-8 full-radi cursor-p hover-green-back transition-03 hover-white"></i>
                                </div>

                            </div>
                        )
                    })}

                </div>

            </div>

        </div>
    )
}

export default WishlistPage;