import React, { useState } from "react";
import HeroSection from "./components/HeroSection/HeroSection.jsx";
import FaqSection from "./components/FaqSection/FaqSection.jsx";
import './Home.css';

import { useLinksManager } from "../../lib/customHooks/useLinksManager.js";
import ViewProductCard from "../../components/views/ViewProductCard.jsx";
import { useShopPageManager } from "../../lib/customHooks/useShopPageManager.js";
import { Loader, NoProductLoader } from "../../components/loaders/Loaders.jsx";
import FeatureSection from "../../components/featureSection/FeatureSection.jsx";
import ClientSection from "../../components/clientSection/ClientSection.jsx";
import DZText from "../../components/text/DZText.jsx";

const Home = () => {

  const { restaurant_phone, restaurant_email, restaurant_address, restaurant_lat, restaurant_long } = useLinksManager();

  const { allProducts, isLoadingProducts, filterProducts, productSize, } = useShopPageManager();

  const markers = [
    {
      id: 1,
      name: "Restaurant",
      position: {
        lat: Number(restaurant_lat),
        lng: Number(restaurant_long)
      },
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center">

      <HeroSection />

      <div className="flex flex-col gap-[64px] py-[64px] w-full max-inner items-center">
        <div className="flex items-center flex-col gap-[20px] max-w-[786px]">
          <div className="flex flex-col gap-[6px] items-center">
            <DZText className="text-[#88C74A] font-semibold max-sm:text-[14px]">
              {"Common.products"}
            </DZText>
            <DZText className="text-[#101828] text-[36px] font-semibold max-lg:text-[32px] max-md:text-[28px] max-sm:text-[24px]">
              {"Common.popular_products"}
            </DZText>
          </div>
          <DZText className="text-[#475467] text-[20px] text-center max-lg:text-[18px] max-md:text-[16px] text-sm:text-[14px]">
            {"Popular_product.dicover_customer_fav"}
          </DZText>
        </div>
        {isLoadingProducts ? (
          <Loader svgClassName={'h-80 w-80'} />
        ) : (
          <>
            {filterProducts.length > 0 ? (
              <div className="grid w-full grid-colt-auto-260 gap-[24px]">
                {filterProducts.slice(0, 4).map((product, index) =>
                  product ? (
                    <ViewProductCard key={product?.id || index} product={product} productSize={productSize} />
                  ) : null
                )}
              </div>
            ) : (
              <NoProductLoader />
            )}
          </>
        )}
      </div>

      {/* 
      
      <FeatureSection/>
      
 

      <FaqSection />
      */}
     <ClientSection/>
    </div>
  );
};

export default Home;
