import ReactRoute from './reactRoute/ReactRoute.js';
import TopArrow from './components/topArrow/TopArrow.jsx'
import { Suspense, useEffect } from 'react';
import { getLocalStorage, setLocalStorage } from './components/localStorage/index.js';
import { KEYS, LANGUAGES } from './constants/Index.js';
import SnackBar from './components/snackBar/SnackBar.jsx';

function App() {

  useEffect(() => { fnSetLanguage(); }, []);

  const fnSetLanguage = () => {
    // const isExistLang = getLocalStorage(KEYS.lang);
    // if (isExistLang) { console.log(isExistLang) }
    // else { setLocalStorage(KEYS.lang, LANGUAGES.da) }

    setLocalStorage(KEYS.lang, LANGUAGES.da)
  };

  return (
    <>
      <Suspense>
        <ReactRoute />
        <TopArrow />
      </Suspense>
      <SnackBar />
    </>
  );
}

export default App;