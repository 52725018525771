import React from 'react'
import DZText from '../text/DZText'

const ClientSection = () => {
    return (
        <div className="px-[8vw] max-sm:px-0 py-[96px] bg-[#F9FAFB] w-full justify-center flex items-center">
            <div className="flex items-center max-lg:gap-[64px] max-lg:px-0 max-md:gap-[48px] max-sm:gap-[32px] gap-[120px] max-inner w-full max-md:flex-col">
                <img src="./images/personImage.png" alt="" />
                <div className="flex flex-col gap-[32px]">
                    <DZText className="text-[#101828] text-[28px] font-medium max-md:text-[24px] max-sm:text-[16px]">
                        {"Popular_product.quality_sneakers"}
                    </DZText>
                    <div className="flex flex-col gap-[4px]">
                        <DZText className="text-[#101828] relative after:absolute after:left-[0px] after:top-[50%] pl-[30px] after:translate-y-[-50%] after:w-[24px] after:h-[2px] after:bg-[#101828] font-semibold text-[18px]">
                            {"Popular_product.company_owner_name"}
                        </DZText>
                        <DZText className="text-[#475467]">
                            {"Popular_product.owner"}
                        </DZText>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientSection